<template>
  <div>
    <base-header type="gradient-blue" class="pb-6 pb-8 pt-5 pt-md-8" />
    <b-container class="mt--7" fluid>
      <b-row>
        <b-col>
          <card>
            <datatable
              classe="table table-bordered table-striped"
              :url="datatableUrl"
              ref="datatable"
              :btnExport="false"
              @create="$router.push({ name: 'menus-create' })"
            >
              <div slot-scope="{ actions }">
                <b-dropdown boundary="viewport" size="sm" text="Actions" variant="primary">
                  <b-dropdown-item
                    @click="
                      $router.push({
                        name: 'menus-edit',
                        params: { id: actions.id },
                      })
                    "
                  >
                    <i class="fa fa-edit p-1"></i> Edit
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click.prevent="changeStatus(actions.status, actions.id, actions.enabled_items)"
                  >
                    <i class="fa fa-toggle-on p-1"></i> Change Status
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <template #status="{ data }">
                <small :class="'badge badge-' + (data == 'E' ? 'primary' : 'danger')">
                  {{ data == "E" ? "Enabled" : "Disabled" }}
                </small>
              </template>
              <template #image="{ data }">
                <img :src="resource(data)" width="70" class="rounded" :alt="data" />
              </template>
              <template #categories="{ data }">
                <template v-if="data.length">
                  <small v-for="(item, index) in data" :key="index" class="badge badge-danger mr-1">
                    {{ item }}
                  </small>
                </template>
                <template v-else> - </template>
              </template>
            </datatable>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import MenusService from "features/menus/services/MenusService";
import { resource } from "helpers";

export default {
  name: "Menus",
  data: () => ({
    datatableUrl: MenusService.baseUrl,
    resource,
  }),

  methods: {
    changeStatus(currentStatus, id, enabledItems) {
      if (enabledItems >= 5 && currentStatus === "D") {
        this.$swal({
          title: "Oops!",
          text: "Only five menus can be active at a time.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        });
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "This action changes the item status.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            MenusService.changeStatus(currentStatus === "E" ? "D" : "E", id).then(() => {
              this.$refs.datatable.refresh();
              this.$swal("Changed!", "Status changed", "success");
            });
          }
        });
      }
    },
  },
};
</script>
